import React from "react";
import AcreditacionV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/AcreditacionV01";
import HomeIconV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/HomeIconV01";
import EmbarqueV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/EmbarqueV01";
import HospedajeIconV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/HospedajeIconV01";
import AsistenciaV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/AsistenciaV01";


class Constants extends React.Component {

  /*Url Api Principal: Módulos de Seguridad, Sistema, Administración, Identificación */
  // static API_URL = "https://localhost:5001";//Desarrollo
  //static API_URL = "https://ase-2personneladministracion-backend-dev.azurewebsites.net";//Azure Static Web App
  static API_URL = "https://apimwlt.azure-api.net/admpal"; //Produccion
  //static API_URL = "https://ase-2personneladministracion-be-paltarumi.azurewebsites.net"; //Paltarumi
  //static API_URL = "https://2pbackendwlt.azurewebsites.net"; //AZURE V1
  //static API_URL = "https://apps.whitelion.pe"; //AZURE V2

  /*Url Api Asistencia: Módulo de Asistencia */ 
  //static API_URL_ASISTENCIA =  "https://localhost:5002"; //Desarrollo
   static API_URL_ASISTENCIA = "https://apimwlt.azure-api.net/asispal";//Produccion
  //static API_URL_ASISTENCIA = "https://ase-2personnelasistencia-be-paltarumi.azurewebsites.net";//Paltarumi
  //static API_URL_ASISTENCIA = "https://ase-2personnelasistenciaadm-backend-dev.azurewebsites.net";//Azure Static Web App

  /*Url Api Acreditación: Módulo de Acreditación */
  //static API_URL_ACREDITACION = "https://localhost:5001";//Desarrollo
  static API_URL_ACREDITACION = "https://apimwlt.azure-api.net/acrepal";//Produccion
  //static API_URL_ACREDITACION = "https://ase-2personnelacreditacion-be-paltarumi.azurewebsites.net";//Paltarumi

  /*Url Api Acceso : Módulo de Control de Acceso */
  //static API_URL_ACCESO_CONTROL = "https://localhost:5001";//Desarrollo
  static API_URL_ACCESO_CONTROL = "https://apimwlt.azure-api.net/accpal";//Producion
  //static API_URL_ACCESO_CONTROL = "https://ase-2personnelacceso-be-paltarumi.azurewebsites.net";//Paltarumi

  /*Url Api Acceso : Módulo de Control de Identificación */
  //static API_URL_IDENTIFICACION = "https://localhost:5005";//Desarrollo
  static API_URL_IDENTIFICACION = "https://apimwlt.azure-api.net/admpal";//Producion

  /*Url Api Acceso : Módulo de Control de Comedores */
  //static API_URL_COMEDORES = "https://localhost:5006";//Desarrollo
  static API_URL_COMEDORES = "https://apimwlt.azure-api.net/admpal";//Producion

  /*Url Api Acceso : Módulo de Control de Hotelería */
  //static API_URL_HOTELERIA = "https://localhost:5007";//Desarrollo
  static API_URL_HOTELERIA = "https://apimwlt.azure-api.net/admpal";//Producion

  /*Url Api Acceso : Módulo de Control de Transporte */
  //static API_URL_TRANSPORTE = "https://localhost:5008";//Desarrollo
  static API_URL_TRANSPORTE = "https://apimwlt.azure-api.net/admpal";//Producion


  static API_URL_LOCALHOST = "http://localhost:1010";//::::::-> NO COMENTAR, SE USA PARA EDIT/PRINT BADGE..:::::::
  static AppName = "2PERSONNEL";
  static AppVersion = "V.3.17" //Entrega  2024-12-16->18-10-2024-11-09-2024-> 25-08-2024-> 09-08-20024-> 20-07-2024 -> -11-07-2024 -> 09-07-2024 ->01-07-2024 -> 25-06-2024 -> 18-06-2024
  static SECONDS_TIMEOUT_FOR_LOCK = 400;
  static APLICACION = "AP00";
  static ID_TIPO_APLICACION = "WEB";
  static CAPTCHA_URL = "https://www.google.com/recaptcha/api.js"
  static CAPTCHA_SECRETKEYSITE = "6LeKHoIaAAAAAO4x8ArgbONUAXxENW4AZBTDr76q";
  static NAME_MODULO = "DESCRIPTION.WEB.MODULE.ADMINISTRATION";
  static CLIENTE_LOGOID = "LogoEmpresaPrincipal.png";
  static DESCRIPCION_COMPANIA_DEFAULT = "LAGUNAS NORTE" //Nombre del cliente para los reportes..
  static NOTIFY_BY = "SMTP"; //SMTP/SMS
  static FLAG_SPANISH = "188-peru.svg";//"131-chile.svg";  //Bandera por defecto asignado ->  

  static URL_REPORTING_SERVICES = "/RS/Rpt.aspx";
  static DASHBOARD = {
    ADMINISTRACION: {
      HEAD_COUNT: "https://app.powerbi.com/view?r=eyJrIjoiZGZkMjU4MDQtNzkxOC00OGExLTg4YmUtZmM3YmJmOTA3MjczIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    }, 
    ACREDITACION: {
      INSITE: "https://app.powerbi.com/view?r=eyJrIjoiMTFiMDg3MTktNzU1Zi00OGVkLWFmZWEtNWU4MDkwNmY4OWJmIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      RECUENTO_ACREDITACION: "https://app.powerbi.com/view?r=eyJrIjoiNjlhYmVmZjktZDFmOC00MGFjLWJmZGEtOWJmMmRlMzMwYzc5IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      SEGUIMINETO: "https://app.powerbi.com/view?r=eyJrIjoiMTI1YjdkMGItZjAxMS00NGFkLWJlZDktMzQ3MTJjZDk0ODM4IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      RESUMEN_CONTRATISTA: "https://app.powerbi.com/view?r=eyJrIjoiMjU2OGJkZmEtNjlmYS00MzNmLWI3ZjItMjUxOTM0YmRiMjI5IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    },
    ACCESO: {
      ACCESO_AREA_CRITICA: "https://app.powerbi.com/view?r=eyJrIjoiYTJkNThhYjktNmQ4ZC00YmRjLTljOGYtMzUzNTNiMzJhNjQ0IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      CONTROL_DE_PERSONAL: "https://app.powerbi.com/view?r=eyJrIjoiZTJhYTZiZmQtNWEwMi00MGNlLThkOGYtZmNjNTk3ZTg0NjFhIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      TOP_INCUMPLIMIENTO_PERSONA: "https://app.powerbi.com/view?r=eyJrIjoiNDRmZmUyNmUtNDVjYy00Zjk5LThjMTktOGNjM2RmYzhjMzQyIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      RECUENTO_ENTRADAS_SALIDAS: "https://app.powerbi.com/view?r=eyJrIjoiNWM5ZTRlMzMtYzg3NC00MDc3LTk0NjktNzRkZmIzYjJlNzliIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      HORAS_PICO_INGRESO_SALIDA: "https://app.powerbi.com/view?r=eyJrIjoiOTk1NDljZmEtOWU1Zi00MTk0LTk0MDYtZTgzZThlMGU1MThmIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      REQUISITOS_POR_VENCER: "https://app.powerbi.com/view?r=eyJrIjoiMzFjOTEyYjItZjI4Yy00ZWQzLTg0ZDItOTEwZTg5ZTBmNWZkIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9"
    },
    CASINO:{
      D001_REPORTE_CONSUMO_COSTO: "https://app.powerbi.com/view?r=eyJrIjoiZmZkODU3MGUtNjcyZC00ZjliLTk5NDUtOGUzY2EyZjA0NDVmIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      D002_DETALLE_CONSUMO: "https://app.powerbi.com/view?r=eyJrIjoiNDM3OGUwMzktZjA0MS00YmMyLWJlNjktOGQ3M2YwMDFmMzVkIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    },
    CAMPAMENTOS:{
      D001_CAMPAMENTO: "https://app.powerbi.com/view?r=eyJrIjoiYTY1ZDk5MDAtNGRkZS00MGFkLTg3NzItYWNiY2IwMzlmYmQzIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      D002_RESUMEN_HABITABILIDAD: "https://app.powerbi.com/view?r=eyJrIjoiM2YwMzA1MzQtYzBiOS00YmI1LTk3ODEtNTVkZmVmZjY3MGIzIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      D003_DETALLE_HABITABILIDAD: "https://app.powerbi.com/view?r=eyJrIjoiZTI0ODJjMjMtN2U1MS00YzMwLWI1OTgtOWFmOTdjYWFkZDY3IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    },
    TRANSPORTE: {
      RESUMEN_DE_TRANSPORTE: "https://app.powerbi.com/view?r=eyJrIjoiMDhjZjJlY2MtMTY2Ni00ZmM2LWI0MmQtZWQyMDcxNDY3MDc2IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    }, 
    IDENTIFICACION: {
      RESUMEN_DE_IMPRESION_FOTOCHECK: "https://app.powerbi.com/view?r=eyJrIjoiM2FmYmE5MmYtNDlmNS00N2Q5LWE1NGItNjc0MzYxYzFmZjE0IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    }, 

    
  };
  // static MENU_PRINCIPAL = [
  //   { classIcon: '2personnel', text: "CONFIG.SISTEMA", activo: 1, accion: null },
  //   { classIcon: 'acreditacion', text: 'ACCREDITATION.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8086/' },
  //   { classIcon: 'Transporte', text: 'TRANSPORT.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8085/' },
  //   { classIcon: 'hoteleria', text: 'HOSPITALITY.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8087/' },
  //   { classIcon: 'asistencia', text: 'ASSISTANCE.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8093/' },

  // ];

  static MENU_PRINCIPAL = [
    { icon: <HomeIconV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: '2personnel', text: 'DESCRIPTION.WEB.MODULE.ADMINISTRATION', activo: 1, accion: null },
    { icon: <AcreditacionV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'acreditacion', text: 'DESCRIPTION.WEB.MODULE.ACREDITACION', activo: 1, accion: 'https://acreditacion.paltarumi.2personnelapps.com/#/' },
    //{ icon: <EmbarqueV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'Transporte', text: 'DESCRIPTION.WEB.MODULE.TRANSPORTE', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8085/' },
    //{ icon: <HospedajeIconV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'hoteleria', text: 'DESCRIPTION.WEB.MODULE.HOSPEDAJE', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8087/' },
    { icon: <AsistenciaV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'asistencia', text: 'DESCRIPTION.WEB.MODULE.ASISTENCIA', activo: 1, accion: 'https://asistencia.paltarumi.2personnelapps.com/#/' },
  ];

  static AUTENTICATE_AD = "N";//Agregar autenticacion AZURE AD
  static AZURE_AD_APP_ID = "" //Produccion
  static AZURE_AD_SCOPE_DEFAULT = "api://xxxxx/access_as_user" //Produccion
  static AZURE_AD_AUTHORITY = "https://login.microsoftonline.com/xxxxx"


  //static AZURE_AD_APP_ID = "34269c8c-3dc9-4a3d-a40c-4e414044e893" //Desarrollo
  //static AZURE_AD_SCOPE_DEFAULT = "api://a5496d8b-3df1-4767-8d0c-2197206fdea9/access_as_user" //Desarollo
 
 static EXECUTE_AZURE_FUNCTION = "N"; //Activar el la ejcución a demanda de Azure Functions
 
 /*
  * Configuración - Api Genera Token de Minsur
  **/
  static USAR_API_GENERA_TOKEN = "N";
  static API_GENERA_TOKEN_URL = "https://localhost:7207/api/login_token";
  static API_GENERA_TOKEN_CLIENT_ID = "";
  static APIM_SUBSCRIPTION_KEY = "";
  
}

export default Constants;
