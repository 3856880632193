import axios from "axios";
import Constants from "../../store/config/Constants";
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

export const URL = Constants.API_URL + "/api/SeguridadUsuarioCompaniaMandante";

export const serviceUsuarioCompaniaMandante = {
  listar: (params) => {
    return from(axios.get(`${URL}/listar`, { params })).pipe(
      map(result => result.data.result)
    ).toPromise();
  },

  crear: (params) => {
    return from(axios.post(`${URL}/crear`, params)).pipe(
      map(result => result.data.result)
    ).toPromise();
  },

  obtenerCompaniaActual: () => {
    return from(axios.get(`${URL}/current`, {})).pipe(
      map(result => result.data.result)
    ).toPromise();
  }
}